import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const listGroupsBySchool = (schoolId: string) => {
  return useApi(http.get('/groups', { params: { school_id: schoolId } }));
};

export const getGroupDetails = (groupId: string) => {
  return useApi(http.get(`/groups/${groupId}`));
};

export const impersonate = (user: string) => {
  return useApi(http.post('auth/impersonate', { user }));
};
